<template>
  <div class="Error__wrapper">
    <div class="Error__brand">
      <div class="Error__brand--logo">
        <NuxtLink to="/dashboard">
          <IconKingsConference />
        </NuxtLink>
      </div>
    </div>
    <div class="Error__main-container">
      <div class="Error__main-wrapper">
        <div class="Error__header">
          <IllustrationPageNotFound />
          <h2 class="Error__header--heading">Page not found</h2>
          <p class="Error__text">
            The page you are looking for doesn't exist or has been moved.
          </p>
        </div>
        <NuxtLink class="Error__button" to="/dashboard">
          Go to KingsConference
        </NuxtLink>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import type { NuxtError } from "#app";
import * as Sentry from "@sentry/vue";

type Props = {
  error: NuxtError;
};

const props = defineProps<Props>();

onMounted(() => {
  Sentry.captureException(props.error, { data: "error-page" });
});
</script>
<style scoped lang="scss">
.Error {
  &__wrapper {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    min-height: 100vh;
    min-height: 100dvh;
    background: $color-primary-background;
    @include md {
      justify-content: center;
    }
  }
  &__brand {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
    &--logo {
      display: inline-flex;
      position: relative;
      top: 37px;
      left: 50%;
      transform: translateX(-50%);
    }
  }
  &__main-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 16px;
    border-radius: 20px 20px 0 0;
    width: 100%;
    height: calc(100vh - 120px);
    height: calc(100dvh - 120px);
    background: $color-basic-white;
    margin-top: 20px;
    z-index: 1;
    @include md {
      height: unset;
      width: 440px;
      padding: 32px 20px;
      border-radius: 20px;
    }
  }
  &__main-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
  }
  &__header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 245px;
    &--heading {
      @include header-s;
      color: $color-element-dark-strong;
      margin: 0;
      max-width: 375px;
      text-align: center;
      text-wrap: balance;
    }
  }
  &__text {
    @include body-l;
    color: $color-element-dark-strong;
    margin-top: 8px;
    margin-bottom: 40px;
    text-align: center;
  }
  &__button {
    @include button-primary;
    text-align: center;
    text-decoration: none;
    margin-top: auto;
  }
}
</style>
