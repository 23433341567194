import * as Sentry from "@sentry/vue";

export default defineNuxtPlugin({
  name: "sentry",
  parallel: true,
  setup(nuxtApp) {
    const vueApp = nuxtApp.vueApp;
    const router = useRouter();

    const config = useRuntimeConfig();

    Sentry.init({
      app: vueApp,
      dsn: config.public.sentryDsnPublic,
      integrations: [Sentry.browserTracingIntegration({ router })],
      tracesSampleRate: 1.0,
    });
  },
});
