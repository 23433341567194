import { default as device_45preferencesK2HW0XYCS6Meta } from "/code/pages/dashboard/device-preferences.vue?macro=true";
import { default as index8Mn5LmJV3VMeta } from "/code/pages/dashboard/index.vue?macro=true";
import { default as roomsKThUkRtOoPMeta } from "/code/pages/dashboard/rooms.vue?macro=true";
import { default as indexKRDw4UYEiPMeta } from "/code/pages/index.vue?macro=true";
import { default as loginT5FXYAtKoAMeta } from "/code/pages/login.vue?macro=true";
import { default as logoutQD54S36jJ5Meta } from "/code/pages/logout.vue?macro=true";
import { default as meeting_45endedQIP1Hq8e4cMeta } from "/code/pages/meeting-ended.vue?macro=true";
import { default as _91id_93LDhZA2M96OMeta } from "/code/pages/meeting/details/[id].vue?macro=true";
import { default as privacy_45policybH3GFCsZHVMeta } from "/code/pages/privacy-policy.vue?macro=true";
import { default as audiotSfmNVKnKVMeta } from "/code/pages/room/[privateUrl]/audio.vue?macro=true";
import { default as expiredSb5QrLR9jFMeta } from "/code/pages/room/[privateUrl]/expired.vue?macro=true";
import { default as indexJH856EtZCJMeta } from "/code/pages/room/[privateUrl]/index.vue?macro=true";
import { default as videoN5ByUJpT1TMeta } from "/code/pages/room/[privateUrl]/video.vue?macro=true";
import { default as terms_45and_45conditionscM0DGUw1OpMeta } from "/code/pages/terms-and-conditions.vue?macro=true";
export default [
  {
    name: device_45preferencesK2HW0XYCS6Meta?.name ?? "dashboard-device-preferences",
    path: device_45preferencesK2HW0XYCS6Meta?.path ?? "/dashboard/device-preferences",
    meta: device_45preferencesK2HW0XYCS6Meta || {},
    alias: device_45preferencesK2HW0XYCS6Meta?.alias || [],
    redirect: device_45preferencesK2HW0XYCS6Meta?.redirect || undefined,
    component: () => import("/code/pages/dashboard/device-preferences.vue").then(m => m.default || m)
  },
  {
    name: index8Mn5LmJV3VMeta?.name ?? "dashboard",
    path: index8Mn5LmJV3VMeta?.path ?? "/dashboard",
    meta: index8Mn5LmJV3VMeta || {},
    alias: index8Mn5LmJV3VMeta?.alias || [],
    redirect: index8Mn5LmJV3VMeta?.redirect || undefined,
    component: () => import("/code/pages/dashboard/index.vue").then(m => m.default || m)
  },
  {
    name: roomsKThUkRtOoPMeta?.name ?? "dashboard-rooms",
    path: roomsKThUkRtOoPMeta?.path ?? "/dashboard/rooms",
    meta: roomsKThUkRtOoPMeta || {},
    alias: roomsKThUkRtOoPMeta?.alias || [],
    redirect: roomsKThUkRtOoPMeta?.redirect || undefined,
    component: () => import("/code/pages/dashboard/rooms.vue").then(m => m.default || m)
  },
  {
    name: indexKRDw4UYEiPMeta?.name ?? "index",
    path: indexKRDw4UYEiPMeta?.path ?? "/",
    meta: indexKRDw4UYEiPMeta || {},
    alias: indexKRDw4UYEiPMeta?.alias || [],
    redirect: indexKRDw4UYEiPMeta?.redirect || undefined,
    component: () => import("/code/pages/index.vue").then(m => m.default || m)
  },
  {
    name: loginT5FXYAtKoAMeta?.name ?? "login",
    path: loginT5FXYAtKoAMeta?.path ?? "/login",
    meta: loginT5FXYAtKoAMeta || {},
    alias: loginT5FXYAtKoAMeta?.alias || [],
    redirect: loginT5FXYAtKoAMeta?.redirect || undefined,
    component: () => import("/code/pages/login.vue").then(m => m.default || m)
  },
  {
    name: logoutQD54S36jJ5Meta?.name ?? "logout",
    path: logoutQD54S36jJ5Meta?.path ?? "/logout",
    meta: logoutQD54S36jJ5Meta || {},
    alias: logoutQD54S36jJ5Meta?.alias || [],
    redirect: logoutQD54S36jJ5Meta?.redirect || undefined,
    component: () => import("/code/pages/logout.vue").then(m => m.default || m)
  },
  {
    name: meeting_45endedQIP1Hq8e4cMeta?.name ?? "meeting-ended",
    path: meeting_45endedQIP1Hq8e4cMeta?.path ?? "/meeting-ended",
    meta: meeting_45endedQIP1Hq8e4cMeta || {},
    alias: meeting_45endedQIP1Hq8e4cMeta?.alias || [],
    redirect: meeting_45endedQIP1Hq8e4cMeta?.redirect || undefined,
    component: () => import("/code/pages/meeting-ended.vue").then(m => m.default || m)
  },
  {
    name: _91id_93LDhZA2M96OMeta?.name ?? "meeting-details-id",
    path: _91id_93LDhZA2M96OMeta?.path ?? "/meeting/details/:id()",
    meta: _91id_93LDhZA2M96OMeta || {},
    alias: _91id_93LDhZA2M96OMeta?.alias || [],
    redirect: _91id_93LDhZA2M96OMeta?.redirect || undefined,
    component: () => import("/code/pages/meeting/details/[id].vue").then(m => m.default || m)
  },
  {
    name: privacy_45policybH3GFCsZHVMeta?.name ?? "privacy-policy",
    path: privacy_45policybH3GFCsZHVMeta?.path ?? "/privacy-policy",
    meta: privacy_45policybH3GFCsZHVMeta || {},
    alias: privacy_45policybH3GFCsZHVMeta?.alias || [],
    redirect: privacy_45policybH3GFCsZHVMeta?.redirect || undefined,
    component: () => import("/code/pages/privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: audiotSfmNVKnKVMeta?.name ?? "room-privateUrl-audio",
    path: audiotSfmNVKnKVMeta?.path ?? "/room/:privateUrl()/audio",
    meta: audiotSfmNVKnKVMeta || {},
    alias: audiotSfmNVKnKVMeta?.alias || [],
    redirect: audiotSfmNVKnKVMeta?.redirect || undefined,
    component: () => import("/code/pages/room/[privateUrl]/audio.vue").then(m => m.default || m)
  },
  {
    name: expiredSb5QrLR9jFMeta?.name ?? "room-privateUrl-expired",
    path: expiredSb5QrLR9jFMeta?.path ?? "/room/:privateUrl()/expired",
    meta: expiredSb5QrLR9jFMeta || {},
    alias: expiredSb5QrLR9jFMeta?.alias || [],
    redirect: expiredSb5QrLR9jFMeta?.redirect || undefined,
    component: () => import("/code/pages/room/[privateUrl]/expired.vue").then(m => m.default || m)
  },
  {
    name: indexJH856EtZCJMeta?.name ?? "room-privateUrl",
    path: indexJH856EtZCJMeta?.path ?? "/room/:privateUrl()",
    meta: indexJH856EtZCJMeta || {},
    alias: indexJH856EtZCJMeta?.alias || [],
    redirect: indexJH856EtZCJMeta?.redirect || undefined,
    component: () => import("/code/pages/room/[privateUrl]/index.vue").then(m => m.default || m)
  },
  {
    name: videoN5ByUJpT1TMeta?.name ?? "room-privateUrl-video",
    path: videoN5ByUJpT1TMeta?.path ?? "/room/:privateUrl()/video",
    meta: videoN5ByUJpT1TMeta || {},
    alias: videoN5ByUJpT1TMeta?.alias || [],
    redirect: videoN5ByUJpT1TMeta?.redirect || undefined,
    component: () => import("/code/pages/room/[privateUrl]/video.vue").then(m => m.default || m)
  },
  {
    name: terms_45and_45conditionscM0DGUw1OpMeta?.name ?? "terms-and-conditions",
    path: terms_45and_45conditionscM0DGUw1OpMeta?.path ?? "/terms-and-conditions",
    meta: terms_45and_45conditionscM0DGUw1OpMeta || {},
    alias: terms_45and_45conditionscM0DGUw1OpMeta?.alias || [],
    redirect: terms_45and_45conditionscM0DGUw1OpMeta?.redirect || undefined,
    component: () => import("/code/pages/terms-and-conditions.vue").then(m => m.default || m)
  }
]