<template>
  <svg
    width="240"
    height="240"
    viewBox="0 0 240 240"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M144.081 110.25L34.4191 110.25C29.4933 110.25 25.5001 113.773 25.5001 118.12L25.5001 214.88C25.5001 219.227 29.4933 222.75 34.4191 222.75L144.081 222.75C149.007 222.75 153 219.227 153 214.88L153 118.12C153 113.773 149.007 110.25 144.081 110.25Z"
      fill="#FDF1F0"
    />
    <path
      d="M74.3377 233.98C113.835 238.906 152.047 157.315 152.047 157.315L144.023 125.865C144.023 125.865 154.832 110.478 152.61 105.773C149.927 100.093 139.295 109.583 126.935 121.817C113.947 134.669 82.4141 175.374 80.1483 178.124C80.1483 178.124 73.2255 170.24 64.981 160.989L61.4226 165.162L28.5671 204.234C40.5584 216.564 58.4416 231.999 74.3377 233.98Z"
      fill="#CA7358"
    />
    <path
      d="M79.2119 177.503L71.0268 188.032"
      stroke="#30242A"
      stroke-width="2.7044"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M147.271 122.613C144.466 126.234 140.689 130.958 137.465 134.412C137.465 134.412 139.229 151.197 124.31 163.385"
      stroke="#30242A"
      stroke-width="2.7044"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M143.749 172.418C143.14 170.85 143.11 168.638 144.605 165.818C144.605 165.818 139.589 166.303 137.874 162.886C135.622 158.398 138.758 154.729 138.758 154.729C138.758 154.729 134.133 154.316 133.706 150.393C133.243 146.139 137.674 142.511 137.674 142.511C137.674 142.511 134.252 141.554 134.808 138.059C135.508 133.634 139.242 130.957 142.652 129.33"
      stroke="#30242A"
      stroke-width="5.40881"
      stroke-miterlimit="10"
      stroke-linecap="round"
    />
    <path
      d="M136.664 201.526C136.561 203.686 138.228 205.523 140.388 205.625C142.548 205.728 144.385 204.062 144.488 201.901L151.302 59.4801C151.405 57.3199 149.739 55.4834 147.578 55.3806C145.418 55.2778 143.582 56.9444 143.479 59.1046L136.666 201.521L136.664 201.526Z"
      fill="#30242A"
    />
    <path
      d="M109.743 89.6374L183.936 93.1852C189.004 93.428 193.302 89.5182 193.549 84.4525L196.117 30.783C196.36 25.7153 192.45 21.4174 187.384 21.1701L113.191 17.6223C108.124 17.3795 103.826 21.2894 103.579 26.3551L101.009 80.029C100.766 85.0967 104.676 89.3946 109.741 89.6419L109.743 89.6374Z"
      fill="#FF7235"
    />
    <path
      d="M158.635 126.613C161.72 130.942 158.706 135.411 158.706 135.411C158.706 135.411 167.613 137.919 163.623 146.404C163.623 146.404 168.982 152.401 162.989 157.762C162.989 157.762 167.074 160.972 161.564 167.333C156.193 173.536 149.63 176.064 146.414 175.149C144.714 174.664 141.615 171.457 144.605 165.819C144.605 165.819 139.589 166.303 137.874 162.886C135.622 158.398 138.758 154.729 138.758 154.729C138.758 154.729 134.133 154.316 133.706 150.393C133.243 146.139 137.674 142.511 137.674 142.511C137.674 142.511 134.252 141.554 134.808 138.059C135.972 130.681 145.572 128.168 148.048 127.276L148.265 123.1C148.265 123.1 155.557 122.293 158.635 126.613Z"
      fill="#CA7358"
    />
    <path
      d="M135.402 143.449C141.801 142.67 151.928 140.504 159.144 135.778"
      stroke="#30242A"
      stroke-width="2.7044"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M137.247 155.368C146.514 154.306 155.488 151.488 163.702 147.069"
      stroke="#30242A"
      stroke-width="2.7044"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M144.631 166.508C149.394 165.379 156.48 162.593 163.117 158.731"
      stroke="#30242A"
      stroke-width="2.7044"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M25.4149 201.868C27.3668 203.556 30.5343 206.214 34.5074 209.259L69.1593 164.709L68.9191 164.495C57.1781 154.019 40.2129 138.882 25.4149 131.439L25.4149 201.868Z"
      fill="#FFBE21"
    />
    <path
      d="M122.438 68.0369L122.741 61.8673L108.186 61.1533L108.388 57.0258L121.975 37.5276L127.58 37.8025L114.014 56.8663L123.312 57.3224L123.655 50.3273L128.577 49.4364L128.178 57.5611L132.306 57.7636L132.082 62.3256L127.954 62.1231L127.652 68.2927L122.438 68.0369Z"
      fill="white"
    />
    <path
      d="M147.769 69.8022C144.959 69.6644 142.587 68.8947 140.653 67.4932C138.719 66.0628 137.274 64.1482 136.318 61.7495C135.363 59.3217 134.962 56.5438 135.115 53.4155C135.269 50.2873 135.939 47.5764 137.126 45.2828C138.314 42.9602 139.939 41.1962 142.002 39.9908C144.066 38.7565 146.502 38.2082 149.312 38.346C152.093 38.4825 154.435 39.2652 156.339 40.6942C158.274 42.0957 159.719 44.0103 160.674 46.438C161.631 48.8367 162.032 51.6002 161.879 54.7285C161.726 57.8567 161.055 60.5821 159.866 62.9047C158.68 65.1983 157.054 66.9623 154.99 68.1966C152.957 69.4034 150.55 69.9386 147.769 69.8022ZM147.956 65.1077C149.549 65.1859 150.96 64.8196 152.191 64.0089C153.451 63.1707 154.47 61.9431 155.246 60.3262C156.022 58.7093 156.466 56.7567 156.578 54.4684C156.691 52.1802 156.44 50.1935 155.825 48.5084C155.211 46.8233 154.317 45.5164 153.143 44.5878C152 43.6316 150.632 43.1144 149.038 43.0363C147.474 42.9595 146.062 43.3403 144.801 44.1785C143.542 44.9878 142.525 46.2009 141.748 47.8178C140.972 49.4347 140.528 51.3873 140.416 53.6756C140.304 55.9638 140.554 57.9505 141.169 59.6356C141.783 61.3207 142.676 62.6421 143.849 63.5997C145.023 64.5283 146.392 65.031 147.956 65.1077Z"
      fill="white"
    />
    <path
      d="M178.275 70.7761L178.578 64.6065L164.023 63.8925L164.225 59.765L177.812 40.2668L183.417 40.5417L169.851 59.6055L179.149 60.0616L179.492 53.0665L184.414 52.1756L184.015 60.3003L188.143 60.5028L187.919 65.0648L183.792 64.8623L183.489 71.0319L178.275 70.7761Z"
      fill="white"
    />
  </svg>
</template>
