
import { updateAppConfig } from '#app/config'
import { defuFn } from 'defu'

const inlineConfig = {
  "nuxt": {
    "buildId": "29e596d4-e7ed-473f-b7ce-3cdd805ee496"
  }
}

// Vite - webpack is handled directly in #app/config
if (import.meta.hot) {
  import.meta.hot.accept((newModule) => {
    updateAppConfig(newModule.default)
  })
}

import cfg0 from "/code/app.config.ts"

export default /*@__PURE__*/ defuFn(cfg0, inlineConfig)
