import Oruga from "@oruga-ui/oruga-next";
import "@oruga-ui/oruga-next/dist/oruga.css";
import "@mdi/font/css/materialdesignicons.min.css";

export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.vueApp.use(Oruga, {
    datepicker: {
      headerButtonsClass: "buttons-position",
      tableCellSelectableClass: "cell-color",
    },
    switch: {
      checkClass: "check-custom",
      checkSwitchClass: "check-switch-custom",
    },
    iconPack: "mdi",
  });
});
